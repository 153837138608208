
import { Component, Prop, Vue } from 'vue-property-decorator';
import { swap } from '@/util/helper';
import { changeLoading } from '@/util/decorators';
import oss from 'baimahu/aliyun/oss';
import Sortable from 'sortablejs';
const uuidv4 = require('uuid/v4');
const { VUE_APP_API: API } = process.env;
import ImageCropper from '@/component/upload-image-cropper.vue';

@Component({
  components: {
    ImageCropper,
  },
})
export default class MultipleImageUpload extends Vue {
  @Prop({ type: String, default: '' }) readonly dir!: string;
  @Prop({ type: String, default: '' }) readonly accept!: string;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  @Prop({ type: Function }) readonly beforeUpload!: any;
  @Prop({ type: Array, default: () => [] }) list!: string[];
  @Prop({ type: Number }) max?: number;
  @Prop({ type: Object, default: () => ({ showPreviewIcon: true, showRemoveIcon: true }) })
  readonly showUploadList!: any;
  @Prop({ type: Boolean, default: false }) noCropper!: boolean;
  @Prop({ type: Boolean, default: false }) readonly defPreview!: boolean;
  preview_image: string = ''; // 预览图片的路径
  preview_visible: boolean = false; // 预览图片的模态框显示状态

  isLoading: boolean = false;
  sortableInstance?: Sortable;
  tempImageFile: File | null = null;
  cropperPromiseResolve: any;

  get fileList() {
    return this.list.map((url: string) => {
      if (typeof url !== 'string') {
        url = 'undefined';
      }
      return { uid: uuidv4(), url, name: url }; // name 属性必填，否则开发环境下会报 warning
    });
  }
  handlePreview(file: any) {
    if(this.defPreview){
      this.preview_image = file.url;
      this.preview_visible = true;
      return
    }
    this.$emit('preview', file.url);
    

  }

  handleRemove(file: any) {
    const list: string[] = this.list.filter((url: string) => url !== file.url);
    this.$emit('update:list', list);
  }

  beforeFileUpload(file: File, fileList: any[]) {
    if (!this.noCropper) {
      return new Promise((resolve, reject) => {
        this.tempImageFile = file;
        this.cropperPromiseResolve = resolve;
      }).then(croppedFile => {
        // croppedFile: file or false, false 直接上传
        if (!(this.beforeUpload && typeof this.beforeUpload === 'function')) {
          return Promise.resolve(croppedFile);
        }
        if (!croppedFile) {
          const beforeUpload = this.beforeUpload(file, fileList);
          return typeof beforeUpload === 'boolean' && !beforeUpload
            ? Promise.reject(null)
            : beforeUpload;
        }
        const beforeUpload = this.beforeUpload(croppedFile, fileList);
        if (beforeUpload === false) {
          return Promise.reject(null);
        }
        if (beforeUpload && typeof beforeUpload.then === 'function') {
          // is beforeUpload a Promise?
          return beforeUpload
            .then(() => Promise.resolve(croppedFile))
            .catch(() => Promise.reject(null));
        }
        return Promise.resolve(croppedFile);
      });
    }

    if (this.beforeUpload && typeof this.beforeUpload === 'function') {
      return this.beforeUpload(file, fileList);
    }
  }

  crop(file: File | boolean) {
    this.cropperPromiseResolve(file);
  }

  @changeLoading(['isLoading'])
  async request(obj: any) {
    const { src } = await oss.upload(`https://${API}/boss/common/oss`, obj.file, true);
    let list: string[] = [...this.list, src];
    list = list.map((item: any) => {
      if (item.indexOf('http') === -1) {
        return `https:${item}`;
      } else {
        return item;
      }
    });
    this.$emit('update:list', list);
  }

  bindSortable() {
    if (this.sortableInstance) {
      this.sortableInstance.destroy();
    }
    const container: HTMLElement = (this.$refs.uploader as any).$el.querySelector(
      '.ant-upload-list'
    );
    this.sortableInstance = Sortable.create(container, {
      onEnd: (e: any) => {
        swap(this.list, e.oldIndex, e.newIndex);
      },
    });
  }

  mounted() {
    this.bindSortable();
  }
}
